import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import VideoModal from "../components/video-modal"
import styled from 'styled-components'

const VideoModalWrapper = styled.section`
height:100%;
.my-12 {margin-top:0!important;margin-bottom:0!important;}

.o-wrapper {padding-left:0;padding-right:0;}
.o-wrapper > .mx-auto {max-width:100%!important}

section.video,
.o-wrapper,
.o-wrapper > .mx-auto,
.my-12 {height:100%;}
`

const Hero = ({ heading, content, image, reverse = false, bg = 'tan', video_url = false, ogImage = false, buttons = [], loaded = false}) => (
  <section className={`hero ${reverse ? 'hero--reverse': ''}`}>
    <div className="o-wrapper">
      <div className={`hero__container relative md:bg-${bg}`}>
        <div className={`flex flex-wrap items-center ${reverse ? 'justify-end': ''}`}>

          <div className="hero__text md:relative w-full md:w-auto z-10 md:my-20 xl:p-20 md:p-12 py-12 bg-white text-black">
            <div className="md:max-w-md">
              <h1 className="u-h1 fade-in-up" dangerouslySetInnerHTML={{ __html: heading }} />
              <p className={`fade-in-up ani-delay-200 text-20px md:max-w-sm mt-${buttons && buttons.length ? '5' : '8'}`} dangerouslySetInnerHTML={{ __html: content }} />
              {buttons && buttons.length &&
                <div className="hero__buttons flex flex-wrap sm:mt-8 mt-5 -m-1  fade-in-up ani-delay-400">
                  {buttons.map(({ button }, cnt) => {
                    if (button.url.slice(0,1) === '#') {

                      return (
                        <a
                          key={'hero'+button.title}
                          href={button.url}
                          onClick={e => {
                            e.preventDefault();
                            if (typeof window !== 'undefined') {
                              const anchor = document.querySelector(button.url);
                              if (anchor) {
                                const anchorTop = anchor.getBoundingClientRect().top;
                                const headerHeight = document.querySelector('header.header').clientHeight;
                                window.scrollTo({ top: anchorTop-headerHeight, behavior: 'smooth' })
                              }
                            }
                          }}
                          className={`w-full sm:w-auto c-btn m-1  ${cnt !== 1 ? 'bg-black text-white hover:bg-green-light hover:text-black': 'c-btn--outline'}`}
                          dangerouslySetInnerHTML={{ __html: button.title }}
                        />
                      )
                    } else if(/^\/(?!\/)/.test(button.url)) {
                      return (
                        <Link
                          key={'hero'+button.title}
                          to={button.url}
                          className={`w-full sm:w-auto c-btn m-1  ${cnt !== 1 ? 'bg-black text-white hover:bg-green-light hover:text-black': 'c-btn--outline'}`}
                          dangerouslySetInnerHTML={{ __html: button.title }}
                        />
                      )
                    } else {
                      return ( <a
                        className={ `w-full sm:w-auto c-btn m-1  ${ cnt !== 1 ? 'bg-black text-white hover:bg-green-light hover:text-black' : 'c-btn--outline' }` }
                        key={ 'hero' + button.title } target={ button.target || '' } href={ button.url } dangerouslySetInnerHTML={ { __html: button.title } } />);
                    }
                  })}
                </div>
              }
            </div>
          </div>

          <div className={`hero__image bg-${bg} w-full md:absolute top-0 bottom-0 ${!reverse ? 'right' : 'left'}-0 max-w-screen `}>
                {video_url &&
                  <VideoModalWrapper>
                    <VideoModal
                      video={video_url}
                      embedType={video_url.includes('youtu') ? 'iframe' : 'html5'}
                      poster={ogImage}
                    />
                  </VideoModalWrapper>
                }

            {!video_url &&
              <Img fluid={image} className="object-cover h-100 md:w-100 w-11/12" draggable={false} />
            }
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default Hero
